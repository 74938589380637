@import './assets/css/fonts.css';

body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background: #e5e5e5;
}

* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
   font-family: 'proxima_novaregular';
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

a {
   text-decoration: none;
}

table,
th,
td {
   border-collapse: collapse;
}

.InputContainer .InputElement {
   position: absolute;
   top: 0;
   left: 30px !important;
   width: auto;
}


/* Date Picker */
.react-datetime-picker__inputGroup__input:invalid {
   background: none !important;
}

.react-datetime-picker * {
   color: #333333;
}

.react-calendar__tile--active {
   background: #1643e6 !important;
   color: white;
} 
/* Dropdown */
 div[class*="-control"] {
     height: 40px;
     width: 100%;
     font-size: 16px;
     font-weight: 500;
     -webkit-transition: all 0.2s;
     transition: all 0.2s;
     box-shadow: 0 0 0 1 #e0e0e0;
     position: relative;
 }
 
.touchMode #root{
   height: 100vh;
   overflow: hidden;
}
.popup-content {
   overflow-y: auto;
   max-height: 85vh;
}