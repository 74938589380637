
@font-face {
    font-family: 'proxima_novaregular';
    src: url('../fonts/proxima_nova_font-webfont.woff2') format('woff2'),
         url('../fonts/proxima_nova_font-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'proxima_novaBold';
    src: url('../fonts/ProximaNova-Alt-Bold-webfont.woff2') format('woff2'),
         url('../fonts/ProximaNova-Alt-Bold-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}